$font: 'Source Sans Pro', sans-serif;

$rask_primary: #2a6b83;
$rask_secondary: #bbc7cf;
$green: #63d177;
$black: #222;

$border: 1px solid #ccc;

$breakpoints: (
    "phone":        400px,
    "phone-wide":   480px,
    "phablet":      560px,
    "tablet-small": 640px,
    "tablet":       768px,
    "tablet-wide":  1024px,
    "desktop":      1248px,
    "desktop-wide": 1440px
);

$z-indexes: (
    "outdated-browser",
    "modal",
    "site-header",
    "page-wrapper",
    "site-footer"
);