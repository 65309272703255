@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');
@import './variables';
@import './keyframes';
@import './mixins';

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

body, input, textarea, button, select, a {
  font-family: $font;
  font-size: 1rem;
}

body {
  height  : 100%;
  overflow: auto;
  position: relative;
  
  &.no-scroll {
    box-sizing: border-box;
    overflow: hidden;
    padding-right: 0px;
  }
}

input, textarea, button, select, a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input, select, textarea, button {
  border-radius: .375rem;
  border: 2px solid $rask_secondary;
  padding: .3125rem .75rem;

  &:focus {
    outline: none;
  }
}

img {
  max-width: 100%;
  height: auto;
}

button, .btn {
  border: 1px solid #eee;
  cursor: pointer;
  @include transition(.2s ease);
}

textarea {
  resize: none;
}

.MuiSelect-nativeInput {
  padding: 0,
}

.container {
  margin: 0 1rem;
  max-width: 1140px;
  padding: 3.5rem 0 4rem;

  @include mq('tablet-wide') {
    margin: 0 auto;
  }
  
  .title {
    color: $rask_primary;
    font-size: 2rem;
    margin: 0 0 1rem;
    text-align: center;
  }
}

.container-wrap {
  @include flexbox;
  margin: 0 auto;
  max-width: 71.25rem;
}

.popup-overlay {
  background-color: rgba(0, 0, 0, .5);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  visibility: hidden;
  z-index: z('modal');

  &.open {
    opacity: 1;
    visibility: visible;

    .popup {
      opacity: 1;
      @include center(scale(1));
      visibility: visible;
    }
  }
  
  .popup {
    background-color: #fff;
    border-radius: .375rem;
    @include center(scale(.85));
    max-width: 18.75rem;
    min-width: 15rem;
    opacity: 0;
    padding: 1rem;
    position: fixed;
    width: 50%;
    text-align: center;
    @include transition(.2s ease);
    visibility: hidden;
    z-index: z('modal');
  
    .choice-box {
      @include flexbox;
      @include justify-content(space-between);
  
      .info {
        font-size: .875rem;
        padding: .375rem 0;
      }
      
      .btn {
        position: relative;
        margin-top: 0;
        width: 45%;
      }
    }
  }
}

.btn {
  background-color: #fff;
  border-radius: 2rem;
  border: 2px solid $rask_primary;
  color: $rask_primary;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  min-width: 3.5rem;
  padding: .25rem .75rem;
  text-decoration: none;

  &.fixed {
    bottom: 4.5rem;
    @include centerX(null);
    position: fixed;
    padding: .5rem 1rem;
  }

  &:focus {
    outline: none;
  }

  &.save-btn, &.cancel-btn {
    color: #fff;
  }

  &.save-btn {
    background-color: $rask_primary;
    border-color: $rask_primary;
  }

  &.cancel-btn {
    background-color: red;
    border-color: red;
  }

  &:disabled {
    background-color: #eee;
    border-color: #eee;
    color: #ccc;
    cursor: not-allowed;
  }
}

.checkbox-wrap {
  cursor: pointer;
  margin: .675rem 1.25rem .675rem 0;
  padding-left: 1.875rem;
  position: relative;
  @include user-select-none;

  input {
    position: absolute;
    opacity: 0;

    &:checked ~ .checkbox, &:checked ~ .radio:after {
      background-color: $green;
      border: 2px solid $green;
    }
  
    &:checked ~ .checkbox:after, &:checked ~ .radio:after {
        display: block;
    }
  }

  .checkbox {
    border-radius: .375rem;

    &:after {
      border: solid #fff;
      border-width: 0 .1875rem .1875rem 0;
      @include centerX(rotate(45deg));
      height: .675rem;
      width: .375rem;
      top: .0625rem;
    }
  }

  .radio {
    border-radius: 50%;

    &:after {
      border: 2px solid $green;
      background: #fff;
      border-radius: 50%;
      @include center(null);
      height: 100%;
      width: 100%;
    }
  }
  
  .checkbox, .radio {
    border: 2px solid $rask_secondary;
    height: 1.25rem;
    left: 0;
    position: absolute;
    top: 0;
    width: 1.25rem;

    &:after {
      content: "";
      display: none;
      position: absolute;
    }
  }
}

.slider-wrap {
  display: inline-block;
  height: 1.875rem;
  width: 3.25rem;
  position: relative;

  input { 
    height: 0;
    opacity: 0;
    width: 0;

    &:checked + .slider {
      background-color: $green;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $green;
    }

    &:checked + .slider:before {
      @include transform(translateX(1.375rem));
    }
  }

  .slider {
    background-color: #ccc;
    border-radius: 1.5rem;
    bottom: 0;
    cursor: pointer;
    left: 0;
    right: 0;
    position: absolute;
    top: 0;
    @include transition(.3s ease);

    &:before {
      background-color: #fff;
      border-radius: 50%;
      bottom: .25rem;
      content: "";
      height: 1.375rem;
      left: .25rem;
      width: 1.375rem;
      position: absolute;
      @include transition(.3s ease);
    }
  }
}

.close-icon, .add-icon {
  color: inherit;
  cursor: pointer;
  height: 1.25rem;
  position: absolute;
  right: 1rem;
  @include centerY(null);
  width: 1.25rem;

  span {
    background: $black;
    border-radius: .675rem;
    display: block;
    height: .375rem;
    left: 0;
    opacity: 1;
    position: absolute;
    width: 100%;
    transform: rotate(0deg);

    &:nth-child(1) {
      @include transform(rotate(45deg));
      top: .5rem;
    }

    &:nth-child(2) {
      @include transform(rotate(-45deg));
      top: .5rem;
    }
  }
}

.popup-overlay {
  .list {
    border-radius: 0;
    height: 100%;
    max-width: 1140px;
    overflow: hidden;
    padding: 0;
    width: 100%;
    text-align: left;  

    @include mq('tablet-wide') {
      border-radius: .375rem;
      height: 90%;
      width: 90%;
    }

    .header {
      max-height: 4.25rem;
      padding: 1rem;
      position: relative;

      h2 {
        line-height: normal;
        margin: 0;
      }
    }

    .choice-box {
      background-color: #fff;
      border: none;
      bottom: 0;
      left: 0;
      max-height: 4.25rem;
      right: 0;
      padding: 1rem;
      position: fixed;
    }
  }
}

.snackbar {
  bottom: 5rem;
}

.offline {
  background-image: url(../assets/log_in_background.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 105%;
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;

  .info {
    @include center(null);
    color: #fff;
    position: relative;
    width: 90%;

    .title {
      color: #fff;
      text-shadow: 0 0 .5rem rgba(0, 0, 0, .55);
    }

    .offline-icon {
      display: block;
      margin: 0 auto;
      height: 100%;
      filter: drop-shadow(0 0 .5rem rgba(0, 0, 0, .55));
      width: 75%;

      @include mq('tablet-wide') {
        width: 25%;
      }
    }
  }
}

.basistypes, .change-view {
  @include flexbox;
  @include justify-content(space-around);

  .type {
    background-color: #fff;
    border-bottom: 2px solid $rask_secondary;
    color: $rask_secondary;
    cursor: pointer;
    font-weight: 600;
    height: 1.75rem;
    padding: .375rem;
    text-align: center;
    @include transition(.1s ease);
    width: 100%;

    &.active {
        border-bottom: 2px solid $rask_primary;
        color: $rask_primary;
        font-size: 1.125rem;
    }
  }
}

.change-view {
  margin: 0 -1rem 1rem;
}

@import './components/login';