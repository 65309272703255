.login-container {
    background-image: url(../../assets/log_in_background.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 105%;
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw;

    .login {
        background-color: #fff;
        border-radius: .375rem;
        box-shadow: 0px 0px 7px 2px rgba(39, 104, 131, 0.15);
        @include centerY(null);
        left: 2rem;
        margin: auto;
        max-width: 25rem;
        padding: 1rem;
        position: absolute;
        right: 2rem;

        .logo {
            margin-bottom: 1rem;
            width: 100%;
        }

        .login-error {
            animation: fade .2s ease;
            background-color: #ffacac;
            border-top: 3px solid red;
            border-radius: .375rem;
            margin: 0 0 .625rem;
            padding: .375rem .625rem;
            position: relative;

            svg:not(.close-icon) {
                margin-right: .375rem;
            }
        }

        .reset-text {
            margin: 0 0 .625rem;

            &.success {
                margin: 0;
            }
        }
    
        .input-group {
            margin-bottom: 1rem;
    
            label {
                display: inline-block;
                margin-bottom: .375rem;
            }
        
            input {
                @include border-box;
                display: block;
                width: 100%;
            }

            .warning {
                animation: fade .2s ease;
                color: red;
                display: block;
                font-size: .875rem;
            }

            .forgot-pw {
                cursor: pointer;
                color: $rask_primary;
                font-size: .875rem;
                text-align: right;
            }
        }

        .errors {
            .strength {
                border-top: 2px solid #ccc;
                color: $black;
                font-weight: 600;
                position: relative;
                text-align: right;

                &::before {
                    content: "";
                    height: 2px;
                    position: absolute;
                    top: -2px;
                    left: 0;
                    @include transition(.5s ease);
                }

                &.lvl2 {
                    color: red;

                    &::before {
                        background-color: red;
                        right: 75%;
                    }
                }

                &.lvl3 {
                    color: orange;

                    &::before {
                        background-color: orange;
                        right: 50%;
                    }
                }

                &.lvl4 {
                    color: lightgreen;

                    &::before {
                        background-color: lightgreen;
                        right: 25%;
                    }
                }

                &.lvl5 {
                    color: green;

                    &::before {
                        background-color: green;
                        right: 0;
                    }
                }
            }
            .warning {
                animation: fade .2s ease;
                color: red;
                display: block;
                font-size: .875rem;
            }
        }

        .btn {
            @include centerX(null);
            font-size: 1.125rem;
            position: relative;
            width: 100%;
        }
    }
}